<template>
  <div>
    <el-main>
      <!-- 表单 -->
      <el-form class="el-form-search" label-width="140px">
        <el-form-item label="用户账号：">
          <el-input size="small" type="number" v-model="searchForm.u_mobile" placeholder="请输入用户账号"></el-input>
        </el-form-item>
        <el-form-item label="子公司名称：">
          <el-input size="small" v-model="searchForm.subsidiary_name" placeholder="请输入子公司名称"></el-input>
        </el-form-item>
        <el-form-item label="合伙人姓名：">
          <el-input size="small" v-model="searchForm.partner_name" placeholder="请输入合伙人姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input size="small" type="number" v-model="searchForm.mobile" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="添加时间：">
          <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="25px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="u_mobile" label="用户账号" width="110" align="center"></el-table-column>
        <el-table-column prop="subsidiary_name" label="子公司名称" width="180" align="center"></el-table-column>
        <el-table-column prop="partner_name" label="合伙人姓名" width="90" align="center"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" width="110" align="center"></el-table-column>
        <el-table-column prop="identity_card_front" label="身份证正面" width="100" align="center">
          <template v-slot="{ row }">
            <div class="demo-image__preview">
              <el-image style="width: 100px; height: 50px" :src="row.identity_card_front[0]" :preview-src-list="row.identity_card_front"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="identity_card_verso" label="身份证背面" width="100" align="center">
          <template v-slot="{ row }">
            <div class="demo-image__preview">
              <el-image style="width: 100px; height: 50px" :src="row.identity_card_front[0]" :preview-src-list="row.identity_card_front"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="area" label="所在地址" width="300" align="center">
          <template v-slot="{ row }">
            <span>
              {{ row.province_name }}{{ row.city_name }}{{ row.area_name }}{{ row.address }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="service_order_amount" label="服务订单总金额" width="120" align="center">
          <template v-slot="{ row }">
            <span>
              ￥{{ row.service_order_amount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="service_order_sum" label="服务订单总数" width="110" align="center"></el-table-column>
        <el-table-column prop="service_total_amount" label="服务收益总金额" width="120" align="center">
          <template v-slot="{ row }">
            <span>
              ￥{{ row.service_total_amount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="添加时间" width="200" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button @click="detail(scope.row)" type="text" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 翻页 -->
      <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
    <el-dialog title="合伙人详情" :visible.sync="showAdd" width="30%" :before-close="handleClose">
      <el-form ref="form" class="add" :model="addForm" :rules="rules" label-width="110px" label-position="left">
        <el-form-item label="合伙人姓名：">
          {{addForm.partner_name}}
        </el-form-item>
        <el-form-item label="联系电话：">
          {{addForm.mobile}}
        </el-form-item>
        <el-form-item label="身份证正面：">
          <el-image style="width: 100px; height: 50px" :src="addForm.identity_card_front" :preview-src-list="addForm.identity_card_front"></el-image>
        </el-form-item>
        <el-form-item label="身份证背面：">
        <el-image style="width: 100px; height: 50px" :src="addForm.identity_card_verso" :preview-src-list="addForm.identity_card_verso"></el-image>
        </el-form-item>
        <el-form-item label="所在地址：">
          {{addForm.address}}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { matchDataAssign } from '@/util/util';
import { getDateformat } from '@/util/getDate';

export default {
  components: {
    Paging,
  },
  data() {
    return {
      showUserList: !1,
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        u_mobile: '',
        subsidiary_name: '',
        partner_name: '',
        mobile: '',
        start_time: '',
        end_time: '',
      },
      showAdd: !1,
      id: '',
      addForm: {
        partner_name: '',
        mobile: '',
        identity_card_front: '',
        identity_card_verso: '',
        address: '',
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //   搜索
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    // 清空搜索
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        u_mobile: '',
        subsidiary_name: '',
        partner_name: '',
        mobile: '',
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    // 时间格式
    getDateformat(val) {
      return getDateformat(val);
    },
    // 下一页
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    // 获取列表
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.user.partnerList, searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            list[i].identity_card_front = [list[i].identity_card_front];
            list[i].identity_card_verso = [list[i].identity_card_verso];
          }
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 详情
    detail(row) {
      this.showAdd = !0;
      this.addFrom = matchDataAssign(this.addForm, row);
      this.id = row.id;
    },
  },
};
</script>

<style lang="less" scoped>
.selectBox {
    display: flex;
    .el-select {
      flex: 1;
      /deep/ .el-input {
        width: 100% !important;
      }
    }
  }
.el-main {
  background: #fff;
}
.el-form-search {
  margin-bottom: 25px;
}
</style>
